import React from 'react';

import * as Yup from 'yup';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate} from 'react-router-dom';

import {ITenantCreate} from 'modules/tenant/models';
import {useTenantInfo} from 'modules/tenant/hooks';
import {useSingleUseSignupSummaryFromUrl} from 'modules/single-use-signup/hooks/useSingleUseSignupSummaryFromUrl';

import {Chevron} from 'shared/components/Chevron/Chevron';
import {Config} from 'shared/config';
import {RegisterSchoolAdminForm} from 'shared/pages/ConfigureSchool/RegisterSchoolForm/RegisterSchoolAdminForm';

import '../styles.scss';

const registerSchoolFormSchema = Yup.object().shape({
    name: Yup.string().required().min(1),
    timezone: Yup.string().required(),
    firstname: Yup.string().required().min(1),
    lastname: Yup.string().required().min(1),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    passwordConfirm: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    hosts: Yup
        .array()
        .of(Yup
            .string()
            .matches(/^[a-z0-9:/\.]+$/, 'Only lowercase letters (a-z) and numbers (0-9) are allowed')
            .required('Host is required')),
});

export interface ICreateTenantForm extends ITenantCreate {
    passwordConfirm: string;
}

const registerSchoolFormDefaults: ICreateTenantForm = {
    name: '',
    timezone: '',
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    password: '',
    passwordConfirm: '',
    hosts: [],
};

const timezones = [
    'Australia/Sydney',
    'Australia/Melbourne',
    'Australia/Brisbane',
    'Australia/Adelaide',
    'Australia/Perth',
    'Australia/Hobart',
    'Australia/Darwin',
    'Australia/Broken_Hill',
    'Australia/Lord_Howe',
    'Australia/Eucla',
];

interface IRegisterSchoolFormProps {
    onSubmit: (form: ICreateTenantForm) => void;
    onToggle: () => void;
    isSubmitting: boolean;
}


export const RegisterSchoolForm = ({onSubmit, isSubmitting, onToggle}: IRegisterSchoolFormProps) => {
    const {t} = useTranslation();
    const [currentTimezone, setCurrentTimezone] = React.useState<string | undefined>();
    const [currentAcronym, setCurrentAcronym] = React.useState<string>('');
    const navigate = useNavigate();

    const {tenantInfo} = useTenantInfo();

    const {singleUseSignupSummary} = useSingleUseSignupSummaryFromUrl(tenantInfo.id);

    const {register, setValue, handleSubmit, formState: {errors}, setError} = useForm<ICreateTenantForm>({
        resolver: yupResolver(registerSchoolFormSchema),
        defaultValues: registerSchoolFormDefaults,
    });

    const newSubmit = (form: ICreateTenantForm) => {
        if (form.password !== form.passwordConfirm) {
            setError(
                'passwordConfirm',
                {message: t('registerSchool.passwordConfirm.error', 'Passwords do not match.')},
                {shouldFocus: true},
            );
            return;
        }

        if (!currentTimezone || !currentAcronym?.length || !singleUseSignupSummary) return;

        setValue('username', `${currentAcronym}_admin`);

        onSubmit(form);
    };

    const onConfigureDomain = (acronym: string) => {
        if (acronym.length > 0) {
            const newCurrentAcronym = acronym.toLowerCase();
            setCurrentAcronym(newCurrentAcronym);
        } else {
            setCurrentAcronym('');
        }
    };

    const onSelectTimezone = (timezone: string) => {
        setCurrentTimezone(timezone);
    };

    React.useEffect(() => {
        if (currentAcronym.length > 0) {
            setValue('hosts', [`${currentAcronym}.youngeyes.co`]);
        } else {
            setValue('hosts', []);
        }
    }, [currentAcronym]);

    React.useEffect(() => {
        if (currentTimezone) {
            setValue('timezone', currentTimezone);
        }
    }, [currentTimezone]);

    React.useEffect(() => {
        errors && console.log(errors);
    }, [errors]);

    return (
        <Form onSubmit={(handleSubmit(newSubmit))} className="mt-3">
            <div className="d-flex">
                <Form.Group className="Register--school-name" controlId="name">
                    <Form.Label>{t('common.schoolName', 'School Name')}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        placeholder={t('common.schoolName', 'School Name')}
                        isInvalid={!!errors.name}
                        {...register('name')}
                    />
                    {(errors.name) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.name.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group as={Dropdown} controlId="timezone" className="Register--timezone">
                    <Form.Label>{t('registerSchool.selectTimezone', 'Timezone')}</Form.Label>
                    {currentTimezone ? (
                        <Dropdown.Toggle
                            className="w-100 text-dark Dropdown-toggle"
                        >
                            {currentTimezone}
                        </Dropdown.Toggle>
                    ) : (
                        <Dropdown.Toggle
                            className="w-100 Dropdown-toggle"
                        >
                            {t('registerSchool.clickToSelectTimezone', 'Click to select timezone')}
                        </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu className="w-100">
                        {timezones.map((timezone, index) => (
                            <Dropdown.Item
                                key={index}
                                onClick={() => onSelectTimezone(timezone)}
                            >
                                {timezone.replaceAll('_', ' ')}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Form.Group>
            </div>
            <div className="d-flex">
                <Form.Group className="Register--configure-domain">
                    <Form.Label>{t('registerSchool.configureWorkspaceDomain', 'Configure Workspace Domain')}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        placeholder={t('common.schoolname', 'Enter school acronym...')}
                        isInvalid={!!errors.hosts}
                        onChange={(e) => onConfigureDomain(e.target.value)}
                    />
                    {(errors.hosts) && (
                        <Form.Control.Feedback className="mt-1" type="invalid">
                            {errors.hosts?.map((error, index) => (
                                <span key={index}>{error?.message}</span>
                            ))}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <div className="Register--preview-domain text-center">
                    <Form.Label>{t('registerSchool.workspaceDomainPreview', 'Workspace Domain Preview')}</Form.Label>
                    <p>{currentAcronym.length ? `https://${currentAcronym}.youngeyes.co` : 'Enter your school\'s acronym to preview your workspace domain'}</p>
                </div>
            </div>
            <RegisterSchoolAdminForm errors={errors} register={register} />
            <div className="text-center btn-container">
                <Button
                    className=""
                    onClick={() => (navigate(-1))}
                    variant='secondary'
                    disabled={isSubmitting}
                >
                    <Chevron direction="left" className="mr-2" />
                    {t('common.back', 'Back')}
                </Button>
                <Button
                    className="Register--register-button center-btn"
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}>
                    {isSubmitting ? (
                        t('registerSchool.submitButton', 'Registering School...', {context: 'busy'})
                    ) : (
                        t('registerSchool.submitButton', 'Register School')
                    )}
                </Button>
                <Button
                    className="Register--register-button"
                    variant="secondary"
                    disabled={isSubmitting}
                    onClick={onToggle}
                >
                    {t('registerSchool.selectExistingSchool', 'Select Existing School?')}
                </Button>
            </div>
        </Form>
    );
};