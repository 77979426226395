import { Alert } from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';

import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';

import './styles.scss';

export const RegisterSchoolSuccess = () => {
    const {t} = useTranslation();

    return (
        <FullScreenModal close={false} size='lg'>
            <FullScreenModalTitle>
                {t('registerSchool.success.title', 'School Registered Successfully!')}
            </FullScreenModalTitle>
            <Alert variant="success" className="RegisterSchoolSuccess__text">
                <Trans i18nKey="registerSchool.success.message">
                    One of our team members will verify your registration and set up your school workspace.
                    <br /><br />
                    This process can take up to two business days. We will email you once this process is complete.
                </Trans>
            </Alert>
            <Alert variant="info" className="RegisterSchoolSuccess__text">
                <Trans i18nKey="contact.enquire">
                    If you have any enquiries, feel free to contact us at <a href="mailto:hello@youngeyes.co" className="RegisterSchoolSuccess__email">hello@youngeyes.co</a>
                </Trans>
                <br /><br />
                <Trans i18nKey="contact.support">
                    If you experience any technical issues, please contact us at <a href="mailto:support@youngeyes.co" className="RegisterSchoolSuccess__email">support@youngeyes.co</a>
                </Trans>
            </Alert>
        </FullScreenModal>
    );
};