import React, {useState} from 'react';

import {Alert, Button, Form} from 'react-bootstrap';
import {Link, Params, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {useRecoilValueLoadable} from 'recoil';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {useTenantInfo} from 'modules/tenant/hooks';
import {useTitle} from 'shared/hooks/useTitle';
import {getAxiosErrorOrThrow} from 'shared/utils/error';
import { validateResetPasswordTokenSelector } from 'modules/user/state/reset-password';
import { resetPasswordComplete } from 'modules/user/api/password';

import {Spinner} from 'shared/components/Spinner/Spinner';
import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';

import './style.scss';

type ResetPasswordFormData = {
    username: string;
    password: string;
    confirmPassword: string;
};

const resetPasswordFormSchema = Yup.object().shape({
    username: Yup.string().min(3),
    password: Yup.string().min(6),
    confirmPassword: Yup.string().min(6).oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const ResetPassword = () => {
    const {t} = useTranslation();
    const {register, handleSubmit} = useForm<ResetPasswordFormData>({
        resolver: yupResolver(resetPasswordFormSchema),
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();
    const {tenantInfo} = useTenantInfo();
    const {tokenId} = useParams() as Params<'tokenId'>;

    const validateTokenLoadable = useRecoilValueLoadable(validateResetPasswordTokenSelector({
        tenantId: tenantInfo.id,
        tokenId: tokenId as string,
    }));

    useTitle(t('resetPassword.browserTitle', 'Reset Password'));

    const onSubmit = handleSubmit(({password}) => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await resetPasswordComplete({
                    tenantId: tenantInfo.id,
                    tokenId: tokenId as string,
                    password,
                });
                toast.info('Password reset, you can log in now');
                navigate('/login');
            } catch (error) {
                toast.error(getAxiosErrorOrThrow(error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <FullScreenModal className="ResetPassword" close={false} size="sm">
            <FullScreenModalTitle>
                {t('resetPassword.pageTitle', 'Reset Your Password')}
            </FullScreenModalTitle>
            {validateTokenLoadable.state === 'loading' && (
                <div className="text-center">
                    <Spinner />
                </div>
            )}
            {validateTokenLoadable.state === 'hasError' && (
                <Alert variant="warning">
                    <Trans i18nKey="resetPassword.error.messages">
                        The link has expired or is not correct. <Link to="/forgot">Reset your
                            Password</Link> or <Link to="/login">Log In</Link>.
                    </Trans>
                </Alert>
            )}
            {validateTokenLoadable.state === 'hasValue' && (
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="ResetPassword__password" className="ResetPassword__password-group">
                        <Form.Control
                            type="password"
                            placeholder={t('resetPassword.newPassword.placeholder', 'New Password')}
                            disabled={isSubmitting}
                            autoFocus
                            className="FullScreenModal__form-control Login__password-input"
                            autoComplete="new-password"
                            {...register('password')}
                        />
                    </Form.Group>
                    <Form.Group controlId="ResetPassword__confirmPassword" className="ResetPassword__password-group">
                        <Form.Control
                            type="password"
                            placeholder={t('resetPassword.confirmPassword.placeholder', 'Confirm Password')}
                            disabled={isSubmitting}
                            className="FullScreenModal__form-control Login__password-input"
                            autoComplete="new-password"
                            {...register('confirmPassword')}
                        />
                    </Form.Group>
                    <Button
                        className="FullScreenModal__button"
                        variant="primary"
                        type="submit"
                        block
                        disabled={isSubmitting}
                    >
                        {isSubmitting && (
                            t('resetPassword.submitButton', 'Saving...', {context: 'busy'})
                        )}
                        {!isSubmitting && (
                            t('resetPassword.submitButton', 'Save Changes')
                        )}
                    </Button>
                </Form>
            )}
        </FullScreenModal>
    );
};
