import axios from 'axios';

import {IUser} from 'modules/user/models/user';
import {EmailVerify, IChangeEmailTokenPath, IEmailUpdate} from '../models/email';
import {getAxiosOptions} from 'shared/utils/api';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'shared/config';

export const getEmailURL = (tenantId: number, path: string | number) => {
    return `${Config.userServiceURL}/tenants/${tenantId}/email${path}`;
};

export const updateEmail = async (tenantId: number, emailUpdate: IEmailUpdate): Promise<IUser> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(
        getEmailURL(tenantId, '/update'),
        emailUpdate,
        getAxiosOptions(accessToken),
    );
    return response.data;
};

export const confirmChangeEmail = async (changeEmailTokenPath: IChangeEmailTokenPath) => {
    const accessToken = await getAccessTokenSilently();
    await axios.post(
        getEmailURL(changeEmailTokenPath.tenantId, `/confirm/${changeEmailTokenPath.tokenId}`),
        undefined,
        getAxiosOptions(accessToken),
    );
};

export const verifyEmail = async (
    tenantId: number,
    email_verify: EmailVerify,
) => {
    await axios.post(getEmailURL(tenantId, '/verify'), email_verify);
};
