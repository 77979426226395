import {Form} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {ICreateTenantForm} from 'shared/pages/ConfigureSchool/RegisterSchoolForm/RegisterSchoolForm';

import '../styles.scss';

interface IRegisterSchoolAdminFormProps {
    errors: FieldErrors;
    register: UseFormRegister<ICreateTenantForm>;
}

export const RegisterSchoolAdminForm = ({errors, register}: IRegisterSchoolAdminFormProps) => {
    const {t} = useTranslation();

    return (
        <>
            <h3 className="text-center">
                {t('registerSchool.schoolAdmin', 'School Admin')}
            </h3>
            <Form.Group controlId="createTenantAdmin.firstname">
                <Form.Label>{t('common.firstname', 'First Name')}</Form.Label>
                <Form.Control
                    autoFocus
                    type="text"
                    placeholder={t('common.firstname', 'First Name')}
                    isInvalid={!!errors.firstname}
                    {...register('firstname')}
                />
                {(errors.firstname) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.firstname.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="createTenantAdmin.lastname">
                <Form.Label>{t('common.lastname', 'Last Name')}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('common.lastname', 'Last Name')}
                    isInvalid={!!errors.lastname}
                    {...register('lastname')}
                />
                {(errors.lastname) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.lastname.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="createTenantAdmin.email">
                <Form.Label>{t('common.emailAddress', 'Email Address')}</Form.Label>
                <Form.Control
                    type="email"
                    placeholder={t('common.emailAddress', 'Email Address')}
                    isInvalid={!!errors.email}
                    {...register('email')}
                />
                {(errors.email) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password">
                <Form.Label>{t('common.password', 'Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.password}
                    {...register('password')}
                />
                {(errors.password) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.password.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password.confirm">
                <Form.Label>{t('common.confirmPassword', 'Confirm Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.passwordConfirm}
                    {...register('passwordConfirm')}
                />
                {(errors.passwordConfirm) && (
                    <Form.Control.Feedback className="mt-1" type="invalid">
                        {errors.passwordConfirm.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};