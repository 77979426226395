import {ArrayFilterOperation} from 'shared/models/array';

export enum UserRole {
    TenantAdmin = 'TENANT_ADMIN',
    Admin = 'ADMIN',
    Student = 'STUDENT',
    Teacher = 'TEACHER',
}

export enum UserCreatedByType {
    DEFAULT = 'DEFAULT',
    ADMIN = 'ADMIN',
    SIGNUP = 'SIGNUP',
}

export enum UserTitle {
    Mr = 'Mr',
    Ms = 'Ms',
    Mrs = 'Mrs',
}

export interface ITenantUserKey {
    tenantId: number;
    userId: number;

    [key: string]: number;
}

export interface IUserImage {
    file_id?: number;
    url?: string;
    avatar?: string;
    filename?: string;
}

export interface IUserPermissions {
    assignment_edit: boolean
    assignment_delete: boolean
    course_edit: boolean
    course_delete: boolean
    course_archived_edit: boolean
    progress_edit: boolean
    progress_view: boolean
    users_create: boolean
    users_delete: boolean
    users_view: boolean
    users_archived_edit: boolean
    admin_create: boolean
    live_session_edit: boolean
    live_session_delete: boolean
    tag_edit: boolean
    tag_delete: boolean

    [key: string]: boolean;
}

export interface IUser {
    id: number;
    tenant_id: number;
    firstname: string;
    lastname: string;
    title?: UserTitle;
    byline: string;
    email: string;
    username?: string;
    phone_number?: string;
    tag_ids: number[];
    roles: UserRole[];
    permissions: IUserPermissions;
    image?: IUserImage;
    is_archived: boolean;
    is_deleted: boolean;
    is_active: boolean;
    author_id: number;
    create_type: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    language?: string;
    metadata?: IUserMetadata;
}

export interface IUpdateUser {
    firstname?: string;
    lastname?: string;
    title?: UserTitle;
    username?: string;
    byline?: string;
    email?: string;
    phone_number?: string;
    password?: string;
    image?: IUserImage;
    permissions?: IUserPermissions;
    language?: string;
}

export interface IUserSettingsForm {
    firstname: string;
    lastname: string;
    byline?: string;
    email: string;
    username?: string;
    password?: string;
    phone_number?: string;
    image?: IUserImage;
}

export interface IUserCreate {
    firstname: string;
    lastname: string;
    title?: UserTitle;
    byline?: string;
    email: string;
    username?: string;
    password: string;
    phone_number: string;
    tag_ids: number[];
    roles: UserRole[];
    permissions: string[];
    image?: IUserImage;
}

export interface IResetPasswordCompleteInput {
    tenantId: number;
    tokenId: string;
    password: string;
}

export interface IUserListResult {
    users: IUser[];
    more: boolean;
    next_cursor: string;
}

export interface IUserListQuery {
    tenantId: number;
    cursor?: string;
    limit: number;
    role?: UserRole;
    search_term?: string;
    tag_ids?: number[];
    tags_op?: ArrayFilterOperation;
}

export interface IUpdateUserInput {
    accessToken?: string;
    tenantId: number;
    userId: number;
    updateData: IUpdateUser | IUserSettingsForm;
}

export interface IUserListFilters {
    limit: number;
    page: number;
    role?: UserRole;
    searchTerm?: string;
    tagIds?: number[];
    tagOp?: ArrayFilterOperation;

    [key: string]: number | UserRole | undefined | string | number[];
}

export type IUserListFiltersUpdate = Partial<IUserListFilters>;

export interface IBatchDeleteUsers {
    tenantId: number;
    user_ids: number[];
}

export interface IUserSummary {
    id: number;
    tenant_id: number;
    firstname: string;
    lastname: string;
    roles: UserRole[];
    image?: IUserImage;
    is_archived: boolean;
}

export interface IUserCountResult {
    user_count: number;
}

export interface IUserIdsListResult {
    user_ids: number[];
    more: boolean;
    next_cursor: string;
}

export interface IUserIdsListQuery {
    role?: UserRole;
    search_term?: string;
    tag_ids?: number[];
    tags_op?: ArrayFilterOperation;

    [key: string]: UserRole | undefined | string | number[];
}

export interface IUserBatchQuery {
    user_ids: number[];
}

export interface IBatchUserResult {
    users: IUser[];
}

export interface IUserMetadata {
    is_registered: boolean;
}