import { ITenantListSummaryFilters, tenantListSummaryReadSelector, tenantListSummarySelector } from 'modules/tenant/state/tenant-list-summary';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';


export const useTenantListSummaryLoadable = (filters: ITenantListSummaryFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: tenantListSummarySelector(filters),
        loadable: tenantListSummaryReadSelector(filters),
    });

    return {
        tenantSummaries: data,
        loading,
        error,
    };
};