import axios from 'axios';

import {getAxiosHeaders} from 'shared/utils/api';
import {Config} from 'shared/config';
import {getAccessTokenSilently} from 'shared/utils/token';
import { IDomainListQuery, IDomainListResult, IDomainSummary } from 'modules/domain/models';

export const getDomainApiUrl = (tenant_id: number, path: string): string => {
    return `${Config.tenantServiceURL}/tenants/${tenant_id}/domains${path}`;
};

export const readDomainList = async ({tenant_id, ...domainListQuery}: IDomainListQuery): Promise<IDomainListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getDomainApiUrl(tenant_id, '/'), {
        params: domainListQuery,
        headers: getAxiosHeaders(accessToken),
    });
    return response.data;
};

export const readDomainSummary = async (tenant_id: number): Promise<IDomainSummary> => {
    const response = await axios.get(getDomainApiUrl(tenant_id, '/summary'), {
        params: tenant_id,
    });
    return response.data;
};