import {atom, selectorFamily} from 'recoil';

import {guardRecoilDefaultValue} from 'shared/recoil/utils';
import {CursorList} from 'shared/models/cursor-list';
import {IReadTenantsProps, ITenantSummary} from 'modules/tenant/models';
import {readTenantListSummary} from 'modules/tenant/api';

export interface ITenantListSummaryFilters {
    filters: IReadTenantsProps;
    page: number;

    [key: string]: IReadTenantsProps | number | CursorList | boolean;
}

interface ITenantListSummaryState {
    tenant_summaries: ITenantSummary[];
    filters: ITenantListSummaryFilters;
    cursors: CursorList;
    more: boolean;

    _resetVersion: number;
}

export const tenantListSummaryAtom = atom<ITenantListSummaryState | undefined>({
    key: 'tenantListSummaryAtom',
    default: undefined,
});

export const tenantListSummaryResetAtom = atom<number>({
    key: 'tenantListSummaryResetAtom',
    default: 0,
});

export const tenantListSummarySelector = selectorFamily<ITenantListSummaryState | undefined, ITenantListSummaryFilters>({
    key: 'tenantListSummarySelector',
    get: (filters) => ({get}) => {
        const atomState = get(tenantListSummaryAtom);
        const resetVersion = get(tenantListSummaryResetAtom);
        if (
            atomState &&
            JSON.stringify(atomState.filters) === JSON.stringify(filters) &&
            resetVersion === atomState._resetVersion
        ) {
            return atomState;
        }
        return undefined;
    },
    set: () => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(tenantListSummaryAtom, newValue);
    },
});

export const tenantListSummaryReadSelector = selectorFamily<ITenantListSummaryState, ITenantListSummaryFilters>({
    key: 'tenantListSummaryReadSelector',
    get: ({filters, page}) => async ({get}) => {
        const state = get(tenantListSummarySelector({filters, page}));
        if (state) {
            return state;
        }

        const atomState = get(tenantListSummaryAtom);

        const response = await readTenantListSummary({
            limit: filters.limit,
            cursor: (page === 0 || !atomState) ? undefined : atomState.cursors[page - 1],
        });

        const newCursors = atomState ? [...atomState.cursors] : [];
        newCursors[page] = response.next_cursor;

        const resetVersion = get(tenantListSummaryResetAtom);

        return {
            tenant_summaries: response.tenant_summaries,
            filters: {filters, page},
            cursors: newCursors,
            more: response.more,
            _resetVersion: resetVersion,
        } as ITenantListSummaryState;
    },
});