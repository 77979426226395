import React from 'react';

import * as Yup from 'yup';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';

import {Config} from 'shared/config';
import {FullScreenModalDescription} from 'shared/components/FullScreenModal/FullScreenModalDescription';
import {Chevron} from 'shared/components/Chevron/Chevron';

import {UserCreatedByType, UserRole, UserTitle} from 'modules/user/models/user';
import {ICreateUserForm} from 'users/pages/CreateUser/CreateUser';

const teacherSignupFormSchema = Yup.object().shape({
    firstname: Yup.string().required().min(1),
    lastname: Yup.string().required().min(1),
    title: Yup.string().oneOf(Object.values(UserTitle)).optional(),
    byline: Yup.string().optional(),
    phone_number: Yup.string().optional(),
    email: Yup.string().email().required(),
    password: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    passwordConfirm: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    tag_ids: Yup.array(Yup.string().required()),
    permissions: Yup.array(Yup.string().required()),
    role: Yup.string().oneOf(Object.values(UserRole)).required(),
});

const teacherSignupFormDefaults: ICreateUserForm = {
    firstname: '',
    lastname: '',
    title: undefined,
    email: '',
    byline: '',
    phone_number: '',
    tag_ids: [],
    password: '',
    passwordConfirm: '',
    role: UserRole.Teacher,
    permissions: [],
    created_by_type: UserCreatedByType.SIGNUP,
};

interface ITeacherSignupFormProps {
    isSubmitting: boolean;
    onSubmit: (val: ICreateUserForm) => void;
    onGoBack: () => void;
}

export const TeacherSignupForm = ({isSubmitting, onSubmit, onGoBack}: ITeacherSignupFormProps) => {
    const {t} = useTranslation();
    const [currentTitle, setCurrentTitle] = React.useState<string | undefined>();

    const {register, setValue, handleSubmit, formState: {errors}, setError} = useForm<ICreateUserForm>({
        resolver: yupResolver(teacherSignupFormSchema),
        defaultValues: teacherSignupFormDefaults,
    });

    const newSubmit = (form: ICreateUserForm) => {
        if (form.password !== form.passwordConfirm) {
            setError(
                'passwordConfirm',
                {message: t('signup.passwordConfirm.error', 'Passwords do not match.')},
                {shouldFocus: true},
            );
        } else {
            onSubmit(form);
        }
    };

    const onSelectTitle = (title: UserTitle | undefined) => {
        setValue('title', title);
        setCurrentTitle(title);
    };

    return (
        <Form onSubmit={handleSubmit(newSubmit)}>
            <FullScreenModalDescription className="Signup--text">
                {t('teacherSignupForm.clarification', 'Enter the details of the teacher who will be facilitating the course.')}
            </FullScreenModalDescription>
            <div className="d-flex">
                <Form.Group className="Signup--teacher--first-name" controlId="signup.firstname">
                    <Form.Label>{t('common.firstname', 'First Name')}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        placeholder={t('common.firstname', 'First Name')}
                        isInvalid={!!errors.firstname}
                        {...register('firstname')}
                    />
                    {(errors.firstname) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.firstname.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="Signup--teacher--last-name" controlId="signup.lastname">
                    <Form.Label>{t('common.lastname', 'Last Name')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('common.lastname', 'Last Name')}
                        isInvalid={!!errors.lastname}
                        {...register('lastname')}
                    />
                    {(errors.lastname) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.lastname.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group as={Dropdown} className="Signup--teacher--title">
                    <Form.Label>{t('teacher.title', 'Title')}</Form.Label>
                    {currentTitle ? (
                        <Dropdown.Toggle
                            className="w-100 text-dark Dropdown-toggle"
                        >
                            {currentTitle}
                        </Dropdown.Toggle>
                    ) : (
                        <Dropdown.Toggle
                            className="w-100 Dropdown-toggle"
                        >
                            <Chevron direction="down" size="lg" />
                        </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu>
                        {Object.values(UserTitle).map((title) => (
                            <Dropdown.Item
                                key={title}
                                onClick={() => onSelectTitle(title)}
                            >
                                {t(`teacher.title.${title.toLowerCase()}`, title)}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Item
                            key={'undefined'}
                            onClick={() => onSelectTitle(undefined)}
                        >
                            {t('teacher.title.undefined', 'Prefer not to say')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Form.Group>
            </div>
            <Form.Group controlId="signup.email">
                <Form.Label>{t('common.emailAddress', 'Email Address')}</Form.Label>
                <Form.Control
                    type="email"
                    placeholder={t('common.emailAddress', 'Email Address')}
                    isInvalid={!!errors.email}
                    {...register('email')}
                />
                {(errors.email) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password">
                <Form.Label>{t('common.password', 'Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.password}
                    {...register('password')}
                />
                {(errors.password) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.password.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password.confirm">
                <Form.Label>{t('common.confirmPassword', 'Confirm Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.passwordConfirm}
                    {...register('passwordConfirm')}
                />
                {(errors.passwordConfirm) && (
                    <Form.Control.Feedback className="mt-1" type="invalid">
                        {errors.passwordConfirm.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <div className="text-center">
                <div className="d-flex">
                    <Button
                        className="position-absolute"
                        onClick={onGoBack}
                    >
                        <Chevron direction="left" className="mr-2" />
                        {t('common.back', 'Back')}
                    </Button>
                    <Button className="Signup--signup-button mx-auto" type="submit" variant="primary" disabled={isSubmitting}>
                        {isSubmitting ? (
                            t('signup.submitButton', 'Signing Up...', {context: 'busy'})
                        ) : (
                            t('signup.submitButton', 'Sign Up')
                        )}
                    </Button>
                </div>
                <div className="mt-3">
                    {t('signup.hasAccount', 'Already have an account?')}
                    <a className="ml-2" href="/login">{t('common.logIn', 'Log In')}</a>
                </div>
            </div>
            {/* TODO: implement 'Go Back' button here. */}
        </Form>
    );
};