import React, {useState} from 'react';

import {Button, Form} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';

import {useAuth} from 'shared/hooks/useAuth';
import {Spinner} from 'shared/components/Spinner/Spinner';
import {useTitle} from 'shared/hooks/useTitle';
import {getAxiosErrorOrThrow} from 'shared/utils/error';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useTenantInfo} from 'modules/tenant/hooks';
import {authRedirectAtom} from '../../../modules/auth/state';

import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';

import './style.scss';

type LoginFormData = {
    username: string;
    password: string;
};

const useAuthorizationCode = (invalidate: boolean) => {
    const location = useLocation();
    return invalidate ? undefined : new URLSearchParams(location.search).get('code');
};

export const Login = () => {
    useNavbar(false);
    const {t} = useTranslation();
    const {register, handleSubmit} = useForm<LoginFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {authenticateWithCredentials, authenticateWithCode} = useAuth();
    const navigate = useNavigate();
    const [invalidateAuthorizationCode, setInvalidateAuthorizationCode] = useState<boolean>(false);
    const authorizationCode = useAuthorizationCode(invalidateAuthorizationCode);
    const {tenantInfo} = useTenantInfo();

    const [authRedirect, setAuthRedirect] = useRecoilState(authRedirectAtom);
    const {search} = useLocation();

    React.useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const redirect = searchParams.get('redirect');
        setAuthRedirect(redirect || '/');
    }, [search, setAuthRedirect]);

    useTitle(t('common.logIn', 'Log In'));

    const redirectOnLogin = React.useCallback(() => {
        navigate(authRedirect || '/');
    }, [navigate, authRedirect]);

    // Login using authorization code if present
    React.useEffect(() => {
        if (authorizationCode && !isSubmitting) {
            setIsSubmitting(true);
            (async () => {
                try {
                    await authenticateWithCode({
                        code: authorizationCode,
                    });
                    redirectOnLogin();
                } catch (error) {
                    setInvalidateAuthorizationCode(true);
                    toast.error(getAxiosErrorOrThrow(error));
                } finally {
                    setIsSubmitting(false);
                }
            })();
        }
    }, [authorizationCode, authenticateWithCode, isSubmitting, redirectOnLogin]);

    const onSubmit = handleSubmit(({username, password}) => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await authenticateWithCredentials({
                    username,
                    password,
                });
                redirectOnLogin();
            } catch (error) {
                toast.error(getAxiosErrorOrThrow(error));
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <FullScreenModal close={false} size="sm" className="Login">
            <FullScreenModalTitle>
                {t('common.loginPage.title', 'Log in to Young Eyes {{tenantName}}', {tenantName: tenantInfo.name})}
            </FullScreenModalTitle>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="Login__username" className="FullScreenModal__form-group">
                    <Form.Control
                        className="FullScreenModal__form-control"
                        type="text"
                        placeholder={t('Login.username', 'Username or Email')}
                        disabled={isSubmitting}
                        autoFocus
                        autoComplete="username"
                        {...register('username')}
                    />
                </Form.Group>
                <Form.Group controlId="Login__password" className="Login__password-group">
                    <Form.Control
                        type="password"
                        placeholder={t('common.password', 'Password')}
                        disabled={isSubmitting}
                        className="FullScreenModal__form-control Login__password-input"
                        autoComplete="current-password"
                        {...register('password')}
                    />
                    <Link to="/forgot" className="FullScreenModal__control-link">Forgot?</Link>
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    block
                    className="FullScreenModal__button"
                >
                    {(isSubmitting) && (
                        <>
                            {t('Login.submitButton', 'Logging In', {context: 'busy'})}
                            <Spinner size="sm" />
                        </>
                    )}
                    {(!isSubmitting) && t('Login.submitButton', 'Log In')}
                </Button>
            </Form>
        </FullScreenModal>
    );
};
