import axios from 'axios';

import {
    IReadTenantListSummaryResult,
    IReadTenantsProps,
    IReadTenantsResult,
    ITenant,
    ITenantCreate,
    ITenantInfo,
    ITenantInfoByHostQuery,
    ITenantInfoByIDQuery,
    ITenantUpdate,
} from '../models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getAxiosHeaders, getAxiosOptions} from 'shared/utils/api';
import {Config} from 'shared/config';

export const getTenantUrl = (path: string | number) => {
    return `${Config.tenantServiceURL}/tenants${path}`;
};

// const getTokenUrl = (path: string | number) => {
//     return `${Config.userServiceURL}/token${path}`;
// };

// TODO: refactor registration token for use in tenant list summary
// const getTenantRegistrationToken = async (getTenantRegistrationTokenProps: IGetTenantRegistrationTokenProps): Promise<string> => {
//     const response = await axios.get(getTokenUrl('/register_school'), {
//         params: getTenantRegistrationTokenProps,
//     });
//     return response.data;
// };

export const getTenantInfoURL = (path: string | number) => {
    return `${Config.tenantServiceURL}/tenant_info${path}`;
};

export const readTenantsAsync = async (readTenantsParams: IReadTenantsProps): Promise<IReadTenantsResult> => {
    try {
        const accessToken = await getAccessTokenSilently();
        const response = await axios.get(getTenantUrl('/'), {
            params: readTenantsParams,
            headers: getAxiosHeaders(accessToken),
        });
        return {
            tenants: response.data?.tenants ?? [],
            nextCursor: response.data?.next_cursor,
            more: response.data?.more,
        };
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const readTenantListSummary = async (params: IReadTenantsProps): Promise<IReadTenantListSummaryResult> => {
    const response = await axios.get(getTenantUrl('/summaries'), {
        params,
    });
    return response.data;
};

export const createTenant = async (
    tenantCreate: ITenantCreate,
): Promise<ITenant> => {
    const response = await axios.post(getTenantUrl('/'), tenantCreate);
    return response.data;
};

export async function readTenant(tenantId: number): Promise<ITenant> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getTenantUrl(`/${tenantId}`), getAxiosOptions(accessToken));
    return response.data;
}

export async function updateTenant(tenantId: number, updateData: ITenantUpdate): Promise<ITenant> {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getTenantUrl(`/${tenantId}`), updateData, getAxiosOptions(accessToken));
    return response.data;
}

export const readTenantInfo = async (tenantInfoQuery: ITenantInfoByHostQuery | ITenantInfoByIDQuery): Promise<ITenantInfo> => {
    const response = await axios.get(getTenantInfoURL('/'), {
        params: tenantInfoQuery,
        withCredentials: true,
    });
    return response.data;
};

export const registerSchool = async (
    tenantCreate: ITenantCreate,
    tenantId: number,
    singleUseSignupId: number,
): Promise<ITenant> => {
    const response = await axios.post(
        getTenantUrl('/register_school'),
        tenantCreate,
        {
            params: {
                tenant_id: tenantId,
                single_use_signup_id: singleUseSignupId,
            },
        },
    );
    return response.data;
};