import React from 'react';

import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Button, Dropdown} from 'react-bootstrap';

import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';

import {ITenantSummary} from 'modules/tenant/models';
import {ITenantListSummaryFilters} from 'modules/tenant/state/tenant-list-summary';
import {useTenantListSummaryLoadable} from 'modules/tenant/hooks/useTenantListSummaryLoadable';

import './styles.scss';

export interface ISelectSchoolDropdownProps {
    handleSelection: (selection: ITenantSummary) => void;
    onToggle: () => void;
}

export const SelectSchoolDropdown = ({handleSelection, onToggle}: ISelectSchoolDropdownProps) => {
    const {t} = useTranslation();
    const [selectedSchool, setSelectedSchool] = React.useState<ITenantSummary | undefined>();

    // TODO: fix this
    const [filters, setFilters] = React.useState<ITenantListSummaryFilters>({
        filters: {
            limit: 50,
        },
        page: 0,
    });

    const {tenantSummaries, loading, error} = useTenantListSummaryLoadable(filters);

    const onConfirmSelection = () => {
        if (selectedSchool) {
            handleSelection(selectedSchool);
        } else {
            toast.error('Must select school before confirming selection');
        }
    };

    if (loading) {
        return (
            <LoadingSpinner />
        );
    } else if (error) {
        return (
            <LoadingError />
        );
    } else {
        return (
            <>
                <div className="d-flex">
                    <Dropdown className="Select--dropdown-button">
                        {selectedSchool ? (
                            <Dropdown.Toggle
                                className="w-100 text-dark Dropdown-toggle"
                            >
                                {selectedSchool.name}
                            </Dropdown.Toggle>
                        ) : (
                            <Dropdown.Toggle
                                className="w-100 Dropdown-toggle"
                            >
                                {t('common.select', 'Select School')}
                            </Dropdown.Toggle>
                        )}
                        <Dropdown.Menu className="w-100">
                            {tenantSummaries?.tenant_summaries.map((tenant) => (
                                <Dropdown.Item
                                    key={tenant.id}
                                    onClick={() => setSelectedSchool(tenant)}
                                >
                                    {tenant.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        className="Select--confirm-button"
                        onClick={() => (onConfirmSelection())}
                        disabled={!selectedSchool}
                    >
                        {t('common.confirm', 'Confirm')}
                    </Button>
                </div>
                <div className="text-center">
                    <Button
                        className="Select--redirect-to-registration-form"
                        onClick={onToggle}
                        variant="secondary"
                    >
                        {t('registerSchool.registerNewSchool', 'Register new school?')}
                    </Button>
                </div>
            </>
        );
    }
};